import { useUser } from 'contexts/UserContext';
import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { ArrowUp } from 'lucide-react';
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { TableAccessContext } from 'contexts/TableAccessContext.tsx';
import LeniQAnswerComponent from '../LeniQAnswerComponent';
import LeniQQuestionComponent from '../LeniQQuestionComponent';
import LeniQQuestion from '../LeniQQuestion';
import LeniQCommentInput from '../LeniQCommentInput';
import { AxiosResponse } from 'axios';
import { GptRunModelsData } from 'src/types/Services/GPT/gpt';
import { GptApi } from 'services/api/gpt';
import { UserApi } from 'services/api/user';
import { getScreenCapture } from 'utils/LeniQ/getScreenCapture';
import { processGptResponse } from 'utils/LeniQ/processGptResponse';
import { Shield } from 'lucide-react';
import { PRODUCT_NAMES } from 'data/trackerProductNames';
import { getTableauUrl } from 'utils/getTableauUrl';
import { TableauDashboardContext } from 'contexts/TableauDashboardContext';
import LeniQSuggestionQuestionsTray from '../LeniQSuggestionsTray';

interface LeniQChatProps {
  handleQuestionClick: (question: string) => void;
  isFullScreen: boolean;
}

interface LeniQQuestionAnswerProps {
  question: string;
  questionIndex: number;
  questionId: number;
  isProcessing: boolean;
  renderCompleted: boolean;
  hasError: boolean;
  answer: any;
}

// const DEMO_QUESTIONS = [
//   'Provide me an executive summary of my portfolio',
//   'How is the marketing performance this quarter?',
//   'Provide an update on Troy Boston occupancy',
//   'Identify revenue improvement opportunities',
// ];

const QUESTIONS = [
  'What is the overall lead to application conversion rate for this month?',
  'What is the total number of open work orders?',
  'Tell me main reason tenants are moving out?',
];

const SUGGESTIONS = [
  'Tell me property with longest time to complete work carpentry orders?',
  'Can you tell me the properties with days to complete for over 10 days for this year?',
  'What types of work orders take the longest to complete? Give me top 10 of them?',
  'Which state had the highest number of opened work orders this year across all properties?',
  'Which state recorded the least open work orders this year?',
  'Which unit type currently has the highest number of cancelled work orders?',
  'Which property had the highest number of cancelled HVAC work orders this year?',
  'Which city recorded the most cancelled work orders last month?',
  'What is the highest number of cancelled work orders recorded this year?',
  'Which unit type has the lowest number of cancelled work orders for the current month?',
  'Which city recorded the fewest cancelled work orders this year?',
  'Which state had the lowest number of cancelled work orders last month?',
  'Which work order category had the most completed work orders for 1-bedroom, 1-bathroom Den (A3) units this month?',
  'What category has the highest number of opened work orders?',
  'What is the ratio of cancelled to completed work orders for the last quarter?',
  'Which month this year had the shortest average completion time for all work orders?',
  'In which quarter did we see the highest cancellation rate for electrical work orders?',
  'What is the number of completed vs. cancelled work orders for the last three months?',
  'Which week this year had the highest number of open work orders?',
  'In which month did we see the highest opened HVAC work orders?',
  'Which month this year had the longest average completion time for all work orders?',
  'Which month has the biggest number of completed electrical work orders?',
  'Which month has the biggest number of completion rate for electrical work orders? ',
  'how many opened work orders are completed this month?',
  'Which property has the highest lead to tour conversion rate last month?',
  'Which source has the lowest lead to tour conversion rate this year?',
  'What is the overall lead to application conversion rate for this month?',
  'List the top 5 property ranked by their lead to application conversion rate?',
  'Which first contact type has the highest lead to application conversion rate?',
  'Over the past 12 months, which month had the highest lead to application conversion rate?',
  'Which secondary source has the highest lead to approval conversion rate?',
  'List the top 3 least efficient sources with the lowest lead to application conversion rates?',
  'Which agent has the highest Lead to application conversion rates this year?',
  'Who are you?',
  'Tell me about leni?',
  "What's the lead to tour rate this month?",
  'List tenants who moved out in the last months?',
  'Show all work orders created this month include the specific time it created?',
  'What is the description of the latest work order?',
  'Show the average rent for two-bedroom units?',
  'What is the vacancy of the all my properties?',
  'which property has the best lead to application conversion rate this year, and how does it compare to the average?',
  'Which property had the longest average work order completion time this quarter, and how does it correlate with its lead-to-tour conversion rate?',
  'How many secondary sources had the same lowest lead to approval conversion rate this quarter and by how much? and what are their sources?',
];

const getRandomQuestions = (n: number): string[] => {
  const shuffled = [...SUGGESTIONS].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, Math.min(n, SUGGESTIONS.length));
};

const formSchema = z.object({
  question: z.string().min(1, {
    message: 'A question cannot be empty',
  }),
});

const LeniQChat: React.FC<LeniQChatProps> = ({ isFullScreen }) => {
  const { user: currentUser } = useUser();
  const [questions, setQuestions] = useState<string[]>([]);
  const [suggestionQuestions, setSuggestionQuestions] = useState<string[]>([]);
  const tableAccessContext = useContext(TableAccessContext);
  const [questionsAnswers, setQuestionsAnswers] = useState<LeniQQuestionAnswerProps[]>([]);
  const questionAnswerContainerRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setQuestions(QUESTIONS);
  }, [currentUser]);

  const getDashboardUrl = async () => {
    return getTableauDashboardUrl();
  };
  const {
    tableauDashboardState: { currentDashboard, dashboardTabIndex },
  } = useContext(TableauDashboardContext);

  const getTableauDashboardUrl = async () => {
    const res = await UserApi.getTableauJWT();
    const token = res.data;
    const userId = currentUser?.id ?? '';
    const organisationId = currentUser?.organisationId ?? '';
    const baseUrl = getTableauUrl(currentDashboard, dashboardTabIndex) ?? '';
    const urlParams = new URLSearchParams({ baseUrl, token, organisationId, userId }).toString();
    return `${window.location.origin}/ghost-tableau?${urlParams}`;
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      question: '',
    },
  });

  const isQuestionAboutExecutiveSummary = (question: string) => {
    return question.toLowerCase().includes('executive summary');
  };

  function processQuestion(question: string) {
    const questionId = new Date().getTime();
    setQuestionsAnswers(prevAnswers => {
      return [
        ...prevAnswers,
        {
          question,
          questionIndex: prevAnswers.length + 1,
          questionId,
          isProcessing: true,
          renderCompleted: false,
          hasError: false,
          answer: null,
        },
      ];
    });
  }

  // Add this useEffect to watch for new questions
  useEffect(() => {
    const lastQuestion = questionsAnswers[questionsAnswers.length - 1];
    if (lastQuestion?.isProcessing && !lastQuestion.answer) {
      askGptQuestion(lastQuestion.question, lastQuestion.questionId);
    }
  }, [questionsAnswers]);

  const isTextBeingRendered = () => {
    return questionsAnswers.reduce((isRendering, questionsAnswer) => {
      if (isRendering) {
        return isRendering;
      }
      return !questionsAnswer.renderCompleted;
    }, false);
  };

  useEffect(() => {
    if (!loading && !isTextBeingRendered()) {
      setSuggestionQuestions(getRandomQuestions(2));
    }
  }, [questionsAnswers.length > 0 && questionsAnswers]);

  function setQuestionAnswer(question: string, questionId: number, answer: any, hasError: boolean) {
    setQuestionsAnswers(prevAnswers => {
      return prevAnswers.map(qa => {
        if (qa.questionId === questionId) {
          return { ...qa, answer, questionId: answer.questionId, isProcessing: false, hasError };
        }
        return qa;
      });
    });
  }

  function setQuestionAnswerRendered(questionId: number) {
    setQuestionsAnswers(prevAnswers => {
      return prevAnswers.map(qa => {
        if (qa.questionId === questionId) {
          return { ...qa, renderCompleted: true };
        }
        return qa;
      });
    });
  }

  const askGptQuestion = useCallback(
    async (question: string, questionId: number) => {
      if (!currentUser) return;
      setLoading(true);
      try {
        const userPayload = {
          ...currentUser,
          tables: tableAccessContext?.state?.data?.tables,
        };
        const isDashboardPageLoaded = document.location.pathname.includes('/dashboard/analytics');
        const doesQuestionIsAboutExecutiveSummary = isQuestionAboutExecutiveSummary(question);
        const screenCaptureBase64 =
          doesQuestionIsAboutExecutiveSummary && !isDashboardPageLoaded ? await getScreenCapture() : null;
        const urlToSummarize =
          doesQuestionIsAboutExecutiveSummary && isDashboardPageLoaded ? await getDashboardUrl() : null;
        const previousHistory = questionsAnswers
          .filter(qa => qa.renderCompleted && qa.questionId !== questionId)
          .map(qa => ({
            question: qa.question,
            questionIndex: qa.questionIndex,
            questionId: qa.questionId,
            answer: qa.answer,
          }))
          .sort((a, b) => a.questionIndex - b.questionIndex);
        const res: AxiosResponse<GptRunModelsData> = await GptApi.runModels(
          question,
          userPayload,
          screenCaptureBase64,
          urlToSummarize,
          false,
          previousHistory
        );
        const answerInfo = processGptResponse(res);

        setQuestionAnswer(question, questionId, answerInfo, !answerInfo || answerInfo.hasError);
        // }
      } catch (error) {
        console.log(error);
        setQuestionAnswer(question, questionId, { questionId }, true);
      } finally {
        UserApi.usageTracking({ productName: PRODUCT_NAMES.LENIQ });
        setLoading(false);
      }
    },
    [currentUser, tableAccessContext?.state?.data?.tables, questionsAnswers]
  );

  useEffect(() => {
    if (questionAnswerContainerRef.current && questionAnswerContainerRef.current.parentElement) {
      questionAnswerContainerRef.current.parentElement.scrollTo({
        top: questionAnswerContainerRef.current.scrollHeight,
        behavior: 'smooth', // This enables smooth scrolling
      });
    }
  }, [questionsAnswers]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    onQuestionAsked(values.question);
  }

  function onQuestionAsked(question: string) {
    console.log('question', question);
    setLoading(true);
    processQuestion(question);
    setLoading(false);
    form.setValue('question', '');
  }

  const WhiteDecorativeOverlay = () => (
    <div
      className="absolute  h-12  z-20 "
      style={{
        bottom: isFullScreen ? '172px' : '206px',
        background: 'linear-gradient(0deg, #ffffff, transparent)',
        width: 'calc(100% - 32px)',
      }}></div>
  );

  return (
    <div
      className="p-4 h-full flex flex-col justify-between overflow-scroll"
      style={{ flex: isFullScreen ? '0 0 calc(100% - 240px)' : '0 0 calc(100% - 274px)' }}>
      <WhiteDecorativeOverlay />
      {questionsAnswers.length === 0 && (
        <div>
          <h2 className="display-md font-bold bg-gradient-to-r from-[#ACDFFD] via-[#3F5E75] to-[#101828] bg-clip-text text-transparent bg-[length:200%_200%] animate-scroll-gradient">
            Hi, {currentUser?.firstName}
          </h2>
          <h4 className="text-lg font-bold">Ask LeniQ about your portfolio</h4>
          <div className="flex flex-col gap-2 mt-5">
            {questions.map(question => (
              <LeniQQuestion
                key={question}
                question={question}
                isFullScreen={isFullScreen}
                onClick={() => onQuestionAsked(question)}
              />
            ))}
          </div>
        </div>
      )}

      <div ref={questionAnswerContainerRef} className="pb-8">
        {questionsAnswers.map((questionAnswer: LeniQQuestionAnswerProps) => {
          return (
            <>
              <LeniQQuestionComponent question={questionAnswer.question} />
              <LeniQAnswerComponent
                question={questionAnswer.question}
                answer={questionAnswer.answer}
                hasError={questionAnswer.hasError}
                onQuestionClick={q => onQuestionAsked(q)}
                onRenderedAnimationCompleted={() => {
                  setQuestionAnswerRendered(questionAnswer.questionId);
                }}
              />
            </>
          );
        })}
      </div>
      <div
        className={`fixed bottom-3 ${isFullScreen ? ' flex flex-col' : 'w-[316px]'}`}
        style={
          isFullScreen
            ? { flex: '0 0 calc(100% - 274px)', width: 'calc(100% - 150px)' }
            : { flex: '0 0 calc(100% - 240px)' }
        }>
        {!loading && !isTextBeingRendered() && (
          <LeniQSuggestionQuestionsTray
            questions={suggestionQuestions}
            isFullScreen={isFullScreen}
            onClick={(q: string) => {
              onQuestionAsked(q);
            }}
          />
        )}
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex items-center justify-between p-1 pr-3 m-0 border border-input  rounded-xl shadow-md max-w-[3500px] z-100 bg-white-50">
            <FormField
              control={form.control}
              name="question"
              render={({ field }) => (
                <FormItem className="flex-grow">
                  <FormControl>
                    <LeniQCommentInput
                      onSubmit={() => {
                        if (!loading) {
                          form.handleSubmit(onSubmit)();
                        }
                      }}
                      placeholder="Ask LeniQ a question..."
                      className={isFullScreen ? `` : `w-[275px]`}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <button
              type="submit"
              className={`cursor-pointer p-[5px] bg-gray-400 rounded-full ${loading ? 'opacity-25' : ''}`}
              disabled={loading}>
              <ArrowUp height={15} width={15} className="text-white-50" />
            </button>
          </form>
        </Form>
        <div className="flex items-center justify-center gap-1 mb-4 mt-4">
          <Shield height={15} width={15} className="text-gray-400" />
          <span className="text-xs text-gray-400 mt-1">Privacy first integration with LLMs</span>
        </div>
      </div>
    </div>
  );
};

export default LeniQChat;
