import React from 'react';
import { ChartType, ReportBuilderKPIMap } from 'data/reportBuilderData';
import { MultiSelectGridOptionWithImage } from 'src/types/Forms/Forms';
import {
  transformDataForAgingSummary,
  transformDataForCurrentTenantUnitStatus,
  transformDataForTenantsWithABalance,
  transformReportBuilderDataForVisualization,
} from 'utils/ReportBuilder/dataTransformationFunctions';
import ReportBuilderBarChart from 'components/ReportBuilder/ReportBuilderCharts/ReportBuilderBarChart';
import ReportBuilderLineChart from 'components/ReportBuilder/ReportBuilderCharts/ReportBuilderLineChart';
import ReportBuilderMultiBarChart from 'components/ReportBuilder/ReportBuilderCharts/ReportBuilderMultiBarChart';
import ReportBuilderMultiLineChart from 'components/ReportBuilder/ReportBuilderCharts/ReportBuilderMultiLineChart';
import ReportBuilderFunnelChart from 'components/ReportBuilder/ReportBuilderCharts/ReportBuilderFunnelChart';
import ReportBuilderStackedBarChart from 'components/ReportBuilder/ReportBuilderCharts/ReportBuilderStackedBarChart';
import ReportBuilderCurrentTenantUnitStatus from 'components/ReportBuilder/ReportBuilderCharts/ReportBuilderCurrentTenantUnitStatusChart';
import ReportBuilderTableChart from 'components/ReportBuilder/ReportBuilderCharts/ReportBuilderTableChart';
import ReportBuilderHeatMapChart from 'components/ReportBuilder/ReportBuilderCharts/ReportBuilderHeatMapChart';
import ReportBuilderSingleRowTableChart from 'components/ReportBuilder/ReportBuilderCharts/ReportBuilderSingleRowTableChart';
import ReportBuilderHorizontalBarTableChart from 'components/ReportBuilder/ReportBuilderCharts/ReportBuilderHorizontalBarTableChart';
import DOMPurify from 'dompurify';

interface ReportBuilderResizableItemProps {
  kpi: MultiSelectGridOptionWithImage;
  borderStyle: string | null;
}

const ReportBuilderResizableItem: React.FC<ReportBuilderResizableItemProps> = ({ kpi, borderStyle }) => {
  const resizable =
    kpi.id !== ReportBuilderKPIMap.AGING_SUMMARY &&
    kpi.id !== ReportBuilderKPIMap.TENANTS_WITH_A_BALANCE &&
    kpi.id !== ReportBuilderKPIMap.CURRENT_TENANT_UNIT_STATUS &&
    kpi.id !== ReportBuilderKPIMap.OCCUPANCY_BY_PROPERTY &&
    kpi.id !== ReportBuilderKPIMap.CURRENT_OCCUPANCY_TOTAL &&
    kpi.id !== ReportBuilderKPIMap.TOP_10_TRADE_OUT_GAINS &&
    kpi.id !== ReportBuilderKPIMap.BOTTOM_10_TRADE_OUT_GAINS;

  if (!kpi || (kpi.type !== 'text' && !kpi.data)) return;

  return (
    <div className={`h-full w-full rounded-md ${borderStyle} relative`}>
      {/* Text field KPIs */}
      {kpi.type === 'text' && (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(kpi.content || '') }} className="prose" />
      )}
      {/* All Graph Based KPIs */}
      {kpi.type !== 'text' && <h2 className="text-lg font-bold text-center mb-4">{kpi.label}</h2>}

      {/* Single Bar Charts */}
      {kpi.data && kpi.data.length > 0 && (kpi.chartType === 'barInteger' || kpi.chartType === 'barFloat') && (
        <ReportBuilderBarChart id={kpi.id} data={transformReportBuilderDataForVisualization(kpi.id, kpi.data)} />
      )}

      {/* Single Line Charts */}
      {kpi.data && kpi.data.length > 0 && (kpi.chartType === 'lineInteger' || kpi.chartType === 'lineFloat') && (
        <ReportBuilderLineChart id={kpi.id} data={transformReportBuilderDataForVisualization(kpi.id, kpi.data)} />
      )}

      {/* Multi-Bar Charts */}
      {kpi.data &&
        kpi.data.length > 0 &&
        (kpi.chartType === 'multiBarFloat' || kpi.chartType === 'multiBarInteger') && (
          <ReportBuilderMultiBarChart id={kpi.id} data={transformReportBuilderDataForVisualization(kpi.id, kpi.data)} />
        )}

      {/* Multi-Line Charts */}
      {kpi.data && kpi.data.length > 0 && kpi.chartType === 'multiLineFloat' && (
        <ReportBuilderMultiLineChart id={kpi.id} data={transformReportBuilderDataForVisualization(kpi.id, kpi.data)} />
      )}

      {/* Funnel Charts */}
      {kpi.data && kpi.data.length > 0 && kpi.chartType === 'funnel' && (
        <ReportBuilderFunnelChart id={kpi.id} data={transformReportBuilderDataForVisualization(kpi.id, kpi.data)} />
      )}

      {/* Stacked Vertical Bar Charts */}
      {kpi.data && kpi.data.length > 0 && kpi.chartType === 'stackedBarInteger' && (
        <ReportBuilderStackedBarChart id={kpi.id} data={transformReportBuilderDataForVisualization(kpi.id, kpi.data)} />
      )}

      {/* Current Tenant / Unit Stats KPI - Unique case */}
      {kpi.data && kpi.data?.length > 0 && kpi.id === (ReportBuilderKPIMap.CURRENT_TENANT_UNIT_STATUS as string) && (
        <ReportBuilderCurrentTenantUnitStatus id={kpi.id} data={transformDataForCurrentTenantUnitStatus(kpi.data)} />
      )}

      {/* Heat Map KPIs */}
      {kpi.data && kpi.data.length > 0 && kpi.id === ReportBuilderKPIMap.T6_LEASE_RENEWAL_RATES_BY_PROPERTY && (
        <ReportBuilderHeatMapChart id={kpi.id} data={transformReportBuilderDataForVisualization(kpi.id, kpi.data)} />
      )}

      {/* Tenants With A Balance KPI - Unique case */}
      {kpi.data && kpi.data.length > 0 && kpi.id === ReportBuilderKPIMap.TENANTS_WITH_A_BALANCE && (
        <ReportBuilderHorizontalBarTableChart id={kpi.id} data={transformDataForTenantsWithABalance(kpi.data)} />
      )}

      {/* Aging Summary KPI - Unique case */}
      {kpi.data && kpi.data.length > 0 && kpi.id === ReportBuilderKPIMap.AGING_SUMMARY && (
        <ReportBuilderHorizontalBarTableChart id={kpi.id} data={transformDataForAgingSummary(kpi.data)} />
      )}

      {/* Single Table KPIs */}
      {kpi.data && kpi.data.length > 0 && kpi.id === ReportBuilderKPIMap.CURRENT_OCCUPANCY_TOTAL && (
        <ReportBuilderSingleRowTableChart
          id={kpi.id}
          data={transformReportBuilderDataForVisualization(kpi.id, kpi.data)}
        />
      )}
      {kpi.data &&
        kpi.data.length > 0 &&
        kpi.chartType === (ChartType.TABLE_CHART as string) &&
        kpi.id !== ReportBuilderKPIMap.T6_LEASE_RENEWAL_RATES_BY_PROPERTY &&
        kpi.id !== ReportBuilderKPIMap.CURRENT_OCCUPANCY_TOTAL && (
          <ReportBuilderTableChart id={kpi.id} data={transformReportBuilderDataForVisualization(kpi.id, kpi.data)} />
        )}

      {/* Only show resize handlers for when editing report - Need to use hacky borderStyle check to prevent split second showing of handlers on preview while drawer opens */}
      {borderStyle !== 'border-none' && resizable && (
        <>
          <div className="absolute w-3 h-3 bg-primary-300 rounded-full cursor-nw-resize top-0 left-0 transform -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute w-3 h-3 bg-primary-300 rounded-full cursor-ne-resize top-0 right-0 transform translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute w-3 h-3 bg-primary-300 rounded-full cursor-sw-resize bottom-0 left-0 transform -translate-x-1/2 translate-y-1/2"></div>
          <div className="absolute w-3 h-3 bg-primary-300 rounded-full cursor-se-resize bottom-0 right-0 transform translate-x-1/2 translate-y-1/2"></div>
          <div className="absolute w-3 h-3 bg-primary-300 rounded-full cursor-n-resize top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute w-3 h-3 bg-primary-300 rounded-full cursor-s-resize bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2"></div>
          <div className="absolute w-3 h-3 bg-primary-300 rounded-full cursor-e-resize right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute w-3 h-3 bg-primary-300 rounded-full cursor-w-resize left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
        </>
      )}
    </div>
  );
};

export default ReportBuilderResizableItem;
